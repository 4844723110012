@import '../../styles/common';
.component-CheckoutV2Order-list-code {
  width: 411px;
  padding-right: 36px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > .CheckoutV2Order-promo-list-code {
    background: #fafafa;
    border: 0.568182px solid rgba(0, 0, 0, 0.15);
    border-radius: 5.68182px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 25px 33px;
    & > .CheckoutV2Order-title-list-code {
      font-weight: bold;
      font-size: 13.6364px;
      line-height: 16px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
    & > hr {
      border: 0;
      border-top: 1px solid #333;
      margin: 0 !important;
    }
    & > .CheckoutV2Order-space-code {
      grid-gap: 21px !important;
      gap: None;
      & > .CheckoutV2Order-item-list-code {
        margin-top: 12px;
        & > .CheckoutV2Order-btn-apply-discount {
          width: 100%;
          height: 70px;
          min-height: 30px;
          justify-content: center;
          align-items: center;
          border: 1px #282828 solid !important;
          &:hover {
            background: #282828;
            color: white;
          }
          display: flex;
          flex-direction: row;
        }
      }
    }
  }
}
.button {
  width: 80px;
  height: 200%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #282828;
  color: white;

}
.quantity-box {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 10px;
}

.quantity-box input {
  width: 40px; /* Điều chỉnh kích thước của input */
  text-align: center;
  margin: 10px 5px; /* Khoảng cách giữa input và nút */
  border: none; /* Ẩn khung của input */
  background: transparent; /* Làm nền trong suốt */
}

.quantity-box button {
  width: 20px; /* Điều chỉnh kích thước của nút */
  height: 20px; /* Điều chỉnh chiều cao của nút */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  font-size: 20px;
  cursor: pointer;
  border: 1px solid black; /* Thêm border cho nút */
  background-color: white; /* Màu nền của nút */
  border-radius: 5px; /* Làm bo tròn góc của nút */
}
.discount_title {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 10px 10px;
  overflow-y: hidden;
}
.discount_expiry {
  position: relative;
  box-sizing: border-box;
  display: flex;
  width: 33.33%;
  padding: 10px 10px;
  border-left: 1px dashed #333;
  justify-content: center;
}
.discount_expiry::before,
.discount_expiry::after {
  position: absolute;
  border: 1px solid #333;
  display: block;
  content: '';
  border-radius: 100%;
  background: #fafafa;
  box-sizing: border-box;
  width: 22px;
  height: 22px;
}
.discount_expiry::before {
  top: -11px;
  left: -12px;
}
.discount_expiry::after {
  bottom: -11px;
  left: -12px;
}
.discount_expiry-inner::before,
.discount_expiry-inner::after {
  position: absolute;
  display: block;
  content: '';
  border-radius: 100%;
  background: #fafafa;
  z-index: 1;
  width: 24px;
  height: 24px;
}
.discount_expiry-inner::before {
  top: -19px;
  left: -13px;
}
.discount_expiry-inner::after {
  bottom: -19px;
  left: -13px;
}
.component-CheckoutV2Order {
  @include mobile_tablet {
    width: 100%;
    padding-right: 0px;
    margin-right: 0;
  }
  width: 411px;
  padding-right: 36px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 65px;

  &.hide {
    display: none;
  }
  &.show {
    display: flex !important;
  }

  & > .CheckoutV2Order-promo {
    // height: 110px;
    background: #fafafa;
    border: 0.568182px solid rgba(0, 0, 0, 0.15);
    border-radius: 5.68182px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 25px 33px;

    & > .CheckoutV2Order-title {
      @include mobile_tablet {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.1em;
      }
      font-weight: bold;
      font-size: 13.6364px;
      line-height: 16px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }

    & > .utilities-form-control {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      gap: 10px;
      height: 40px;
      margin-bottom: 10px;

      .input-group {
        flex: 1;
        width: 100px;
        height: 100%;
        input {
          flex: 1;
          width: 100%;
          height: 100%;
        }

        .remove-coupon {
          color: red;
          font-size: 12px;
        }
      }

      & > .button {
        width: 80px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #282828;
        color: white;
      }
    }
  }

  & > .CheckoutV2Order-order {
    @include mobile {
      margin-top: 0px;
    }
    background: #fafafa;
    border: 0.568182px solid rgba(0, 0, 0, 0.15);
    border-radius: 5.68182px;
    display: flex;
    flex-direction: column;
    padding: 23px;
    margin-top: 55px;

    & > .CheckoutV2Order-title {
      font-weight: bold;
      font-size: 13.6364px;
      line-height: 16px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }

    & > .CheckoutV2Order-products {
      margin-top: 8px;
      margin-bottom: 17px;
      display: flex;
      flex-direction: column;
      gap: 41px;
      border-top: 0.568182px solid rgba(0, 0, 0, 0.3);
      border-bottom: 0.568182px solid rgba(0, 0, 0, 0.3);
      padding: 12.5px 0;

      & > .CheckoutV2Order-product {
        height: 114px;
        display: flex;
        justify-content: space-between;
        flex-flow: wrap;
        margin-bottom: 10px;
        gap: 5px;

        & > .out-stock {
          width: 100%;
          font-size: 9.09091px;
          color: red;
          height: 40px;
        }

        & > .CheckoutV2Order-image {
          flex: 1;
          height: 114px;
          position: relative;

          & > picture {
            img {
              width: 100%;
              height: 100%;
            }
          }

          .discount {
            position: absolute;
            top: -10px;
            left: -10px;
            width: 40px;
            height: 40px;
            background: var(--secondary-color);
            border-radius: 50%;
            color: var(--font-color);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 8px;
            z-index: 10;
          }
        }

        & > .CheckoutV2Order-info {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          padding-top: 10px;
          padding-bottom: 10px;

          & > .CheckoutV2Order-name {
            font-weight: bold;
            font-size: 11.3636px;
            line-height: 13px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
          }
          & > .CheckoutV2Order-brand {
            font-weight: 300;
            font-size: 9.09091px;
            line-height: 11px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            margin-top: 4px;
          }

          & > .CheckoutV2Order-variant {
            font-weight: 300;
            font-size: 9.09091px;
            line-height: 11px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            margin-top: auto;
            justify-self: flex-end;
          }
        }

        & > .CheckoutV2Order-pq {
          flex: 1;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-top: 10px;
          padding-bottom: 10px;
          text-align: right;

          & > .CheckoutV2Order-price {
            font-weight: 400;
            font-size: 15px;
            line-height: 13px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            display: flex;
            flex-direction: column;
            gap: 5px;
            align-items: flex-end;
            & > .linethrough {
              text-decoration: line-through;
            }
          }

          & > .CheckoutV2Order-quantity {
            font-weight: 250;
            font-size: 15px;
            line-height: 11px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;
            button {
              width: 20px;
              height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 15px;
              border: 1px #282828 solid;
              &:hover {
                background: #282828;
                color: white;
              }
            }
          }
          & > .CheckoutV2Order-quantity-disabled {
            font-weight: 250;
            font-size: 15px;
            line-height: 11px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;

            button {
              width: 20px;
              height: 20px;
              display: hidden;
              justify-content: center;
              align-items: center;
              font-size: 15px;
              border: 1px grey solid;
            }
          }
        }
      }
    }

    & > .CheckoutV2Order-calculate {
      display: flex;
      flex-direction: column;
      gap: 11px;
      margin-bottom: 35px;

      .divide {
        border-bottom: 0.568182px solid rgba(0, 0, 0, 0.3);
        margin-top: 6px;
        margin-bottom: 6px;
      }

      & > .CheckoutV2Order-row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > .CheckoutV2Order-col1 {
          font-weight: 300;
          font-size: 11.3636px;
          line-height: 13px;
          letter-spacing: 0.1em;
          text-transform: uppercase;
        }

        & > .CheckoutV2Order-col2 {
          font-weight: bold;
          font-size: 11.3636px;
          line-height: 13px;
          text-align: right;
          letter-spacing: 0.1em;
          text-transform: uppercase;
        }

        &.CheckoutV2Order-total {
          gap: 27.32px;
          & > .CheckoutV2Order-col1 {
            font-weight: 700;
            font-size: 13.6364px;
            line-height: 16px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            flex: 1;
            display: flex;
            align-items: flex-end;
            gap: 3px;
            &::after {
              content: '';
              width: 100%;
              height: 3px;
              border-top: 1px black dotted;
            }
          }

          & > .CheckoutV2Order-col2 {
            font-weight: 700;
            font-size: 13.6364px;
            line-height: 16px;
            text-align: right;
            letter-spacing: 0.1em;
            text-transform: uppercase;
          }
        }
      }
    }
  }
  & > .selectFreeProduct{
    .groupFreeProduct{
      .freeProductBox{
        .freeProduct{
          display: flex;
          padding: 10px;
          .freeProduct-image {
            flex: 1;
            position: relative;
      
            & > picture {
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
          & > .freeProduct-info {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;

  
            & > .freeProduct-name {
              font-weight: bold;
              font-size: 11.3636px;
              line-height: 13px;
              letter-spacing: 0.1em;
              text-transform: uppercase;
            }
            & > .freeProduct-brand {
              font-weight: 300;
              font-size: 9.09091px;
              line-height: 11px;
              letter-spacing: 0.1em;
              text-transform: uppercase;
              margin-top: 4px;
            }
  
            & > .freeProduct-variant {
              font-weight: 300;
              font-size: 9.09091px;
              line-height: 11px;
              letter-spacing: 0.1em;
              text-transform: uppercase;
              margin-top: auto;
              justify-self: flex-end;
            }
          }
  
        }
      }
    }
  }
  & > .contact {
    height: 60px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 20px;

    .contact-item {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .image {
        width: 20px;

        img {
          width: 100%;
        }
      }

      .text {
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
        font-size: 14px;
      }

      .text-red {
        color: red;
        font-size: 14px;
      }
    }
  }
  .checkbox-wrapper-6 {
    font-size: 9px;
    margin-left: 1em;
  }
  .checkbox-wrapper-6 .tgl {
    display: none;
  }
  .checkbox-wrapper-6 .tgl,
  .checkbox-wrapper-6 .tgl:after,
  .checkbox-wrapper-6 .tgl:before,
  .checkbox-wrapper-6 .tgl *,
  .checkbox-wrapper-6 .tgl *:after,
  .checkbox-wrapper-6 .tgl *:before,
  .checkbox-wrapper-6 .tgl + .tgl-btn {
    box-sizing: border-box;
  }
  .checkbox-wrapper-6 .tgl::-moz-selection,
  .checkbox-wrapper-6 .tgl:after::-moz-selection,
  .checkbox-wrapper-6 .tgl:before::-moz-selection,
  .checkbox-wrapper-6 .tgl *::-moz-selection,
  .checkbox-wrapper-6 .tgl *:after::-moz-selection,
  .checkbox-wrapper-6 .tgl *:before::-moz-selection,
  .checkbox-wrapper-6 .tgl + .tgl-btn::-moz-selection,
  .checkbox-wrapper-6 .tgl::selection,
  .checkbox-wrapper-6 .tgl:after::selection,
  .checkbox-wrapper-6 .tgl:before::selection,
  .checkbox-wrapper-6 .tgl *::selection,
  .checkbox-wrapper-6 .tgl *:after::selection,
  .checkbox-wrapper-6 .tgl *:before::selection,
  .checkbox-wrapper-6 .tgl + .tgl-btn::selection {
    background: none;
  }
  .checkbox-wrapper-6 .tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: 4em;
    height: 2em;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .checkbox-wrapper-6 .tgl + .tgl-btn:after,
  .checkbox-wrapper-6 .tgl + .tgl-btn:before {
    position: relative;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
  }
  .checkbox-wrapper-6 .tgl + .tgl-btn:after {
    left: 0;
  }
  .checkbox-wrapper-6 .tgl + .tgl-btn:before {
    display: none;
  }
  .checkbox-wrapper-6 .tgl:checked + .tgl-btn:after {
    left: 50%;
  }

  .checkbox-wrapper-6 .tgl-light + .tgl-btn {
    background: #f0f0f0;
    border-radius: 2em;
    padding: 2px;
    transition: all 0.4s ease;
  }
  .checkbox-wrapper-6 .tgl-light + .tgl-btn:after {
    border-radius: 50%;
    background: #fff;
    transition: all 0.2s ease;
  }
  .checkbox-wrapper-6 .tgl-light:checked + .tgl-btn {
    background: #9fd6ae;
  }
  .add-gift-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .wrapper-referral {
    display: flex;
    flex-flow: row;
    margin-top: 1em;

    & > .CheckoutV2Order-toggle-referral-discount {
      font-weight: bold;
      font-size: 13.6364px;
      line-height: 16px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
  }
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: $primary_color;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .wrapper-loading {
    padding-bottom: 40px;
    text-align: center;
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  .lds-roller {
    display: inline-block;
    position: relative;
    width: 0px;
    height: 16px;
    right: -21px;
    height: unset;
    transform: scale(0.3);
    div:after {
      content: ' ';
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: $primary_color;
      margin: -4px 0 0 -4px;
    }
  }
}